export function scrollLink(wrapper = "scroll-link") {
    let smoothScrollLinks = document.querySelectorAll("." + wrapper);

    smoothScrollLinks.forEach(link => {
        link.addEventListener('click', function(event) {
            let href = this.getAttribute('href');
            if (href.includes('#')) {
                event.preventDefault();
                let targetId = href.split('#')[1];
                let targetElement = document.getElementById(targetId);
                if (targetElement) {
                    let elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
                    let offsetPosition = elementPosition - 120;

                    window.scrollTo({
                        behavior: 'smooth',
                        top: offsetPosition
                    });
                }
            }
        });
    });
}